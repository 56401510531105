.scroller {
  /* --scrollbar-color-thumb: hotpink; */
  --scrollbar-color-track: 'transparent';
  --scrollbar-width: thin;
  --scrollbar-width-legacy: 10px;
}

/* Modern browsers with `scrollbar-*` support */
@supports (scrollbar-width: auto) {
  .scroller {
    scrollbar-color: var(--scrollbar-color-thumb) var(--scrollbar-color-track);
    scrollbar-width: var(--scrollbar-width);
  }
}

::-webkit-scrollbar-track {
  border-left: 0 solid transparent;
}

/* Legacy browsers with `::-webkit-scrollbar-*` support */
@supports selector(::-webkit-scrollbar) {
  .scroller::-webkit-scrollbar-thumb {
    background: var(--scrollbar-color-thumb);
  }
  .scroller::-webkit-scrollbar-track {
    background: var(--scrollbar-color-track);
  }
  .scroller::-webkit-scrollbar {
    max-width: var(--scrollbar-width-legacy);
    max-height: var(--scrollbar-width-legacy);
  }
}

.MuiTextField-root {
  border-radius: 12px;
}

[class~='MuiFilledInput-root'] {
  border-radius: 12px;
  border: 2px solid transparent;
}

[class~='MuiFilledInput-root']:focus-within {
  border-radius: 12px;
  border: 2px solid #025cca;
  background-color: #fff;
  transform: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

[class~='MuiFilledInput-root']::before {
  border: none !important;
  transition: none;
}

[class~='MuiFilledInput-root']::after {
  border: none !important;
  transition: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
